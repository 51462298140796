import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { AssetData } from 'src/app/models/assetData.model';
import { ChartConfiguration } from 'chart.js';
import jwt_decode from "jwt-decode";
import { SalesManagementComponent } from './sales-management/sales-management.component';
import { RegionService } from 'src/app/services/region.service';
import { Region } from 'src/app/models/region.model';
import { ToastrService } from 'ngx-toastr';
import { AreaService } from 'src/app/services/area.service';
import { SelectItem } from 'src/app/models/mapFilter.model';
import { Area } from 'src/app/models/area.model';
import { Console } from 'console';
import { AssetsCounts } from 'src/app/models/assetsCounts';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  pageTitle: string = '';
  @ViewChild(SalesManagementComponent) public agmMap: SalesManagementComponent | any = false;

  assetsList: any[];
  regionsList: any[];
  assets = ['Buildings', 'Customers',
    'Poles', 'Transformers'];
  analyticsOverviewData: any;
  analyticsOverview: Map<number, AssetData> = new Map<number, AssetData>();
  assetsCounts: AssetsCounts = new AssetsCounts();
  customersData: AssetData = new AssetData(null, 'Customers');
  buildingsData: AssetData = new AssetData(null, 'Buildings');
  buildingsSalesManagementData: AssetData = new AssetData();;
  buildingsDoughnutData: AssetData = new AssetData();;
  transformersData: AssetData = new AssetData(null, 'Transformers');
  polesData: AssetData = new AssetData(null, 'Poles');
  increasement: number = 0;
  graphData: any = { labels: ['-'] };
  lineGraphData: any;
  formatedGraphData: ChartConfiguration<'bar'>['data'] = { datasets: [] };
  regions: Region[];
  areas: Area[];
  buildingsColors = ['#FEBA4F', '#cc5500', '#cc5500'];
  dtColors = ['#89CFF0', '#191970'];
  poleColors = ['#4b5320', '#B2AC88', '#98FB98'];
  areaOptions: any[];
  regionId: number = 1;
  loggedUser: string = "";

  //buName: string = this.translationService.getByKeyFromStorage('BU');
  //utName: string = this.translationService.getByKeyFromStorage('UT');


  
  graphType : string = "Daily";
  graphDateType : string = "LastUpdateTime";
  pageSize : number = 7;
  page : number = 0;
  areaIds : number[] = []
  reloadMapTrigger : boolean = true;
  selectedAllAreas = true;
  selectedAllRegions= true;
  mapLoaded: boolean = false;
  selectedAssets: SelectItem[] = [
    new SelectItem("Customers", "COMMON.CUSTOMER.PLURAL", "Customers", 1, true, true),
    new SelectItem("Buildings", "COMMON.BUILDING.PLURAL", "Buildings", 2, true, true),
    new SelectItem("Transformers", "COMMON.TRANSFORMER.PLURAL", "Transformers", 3, true, true),
    new SelectItem("LT Poles", "COMMON.POLE.LT_PLURAL", "LT Poles", 4, true, true)
  ];


  constructor(private dashboardService: DashboardService, private cdr: ChangeDetectorRef, private areaService: AreaService, private regionService: RegionService, private toastr: ToastrService, private translationService: TranslationService) {
    this.assetsList = [
      { name: 'All assets', checked: true, value: 0 },
      { name: 'Customers', checked: false, value: 1 },
      { name: 'Buildings', checked: false, value: 2 },
      { name: 'Transformers', checked: false, value: 3 },
      { name: 'DT Poles', checked: false, value: 4 }
    ];
    this.regionsList = [
      { name: 'Select all segments', checked: true },
      { name: 'Accra East', checked: false },
      { name: 'Kumasi', checked: false },
      { name: 'Madina', checked: false },
      { name: 'Tema', checked: false }
    ];
  }

  ngAfterViewInit(): void {
    if (this.agmMap && this.agmMap.map) {
      this.mapLoaded = true;
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.getRegions();
    let user: any = jwt_decode(localStorage.getItem("token")!);
    if (user != undefined) {
      this.loggedUser = user["name"].split(' ')[0];
    }
  }

  reloadDashboard() {
    this.areaIds = this.areas.filter(x => x.checked == true).map(x => x.id);
    this.getGraphData();
    this.getOperationsAnalytics([0, 'Daily']);
    this.getAnalyticsOverview();
    this.dashboardService.getAssetsCounts(this.areaIds).subscribe({
      next: (data) => {
        this.assetsCounts = new AssetsCounts(data?.body?.data);
      },
      error: (error) => console.log(error.statusText)
    });
  }

  increasePage(val: number) {
    if (this.page + val >= 0) {
      this.page += val;
      this.getGraphData();
    }
  }

  changeGraphType(type: string) {
    if (this.graphData != type) {
      this.graphType = type;
      this.page = 0;
      this.getGraphData();
    }

  }
  changeGraphDateType(type: string) {
    if (this.graphDateType != type) {
      this.graphDateType = type;
      this.page = 0;
      this.getGraphData();
    }

  }

  private getRegions() {
    var me = this;
    this.regionService.getAllForSelect().subscribe({
      next: response => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === "OK") {
          this.regions = responseData.data;
          this.regions.forEach(function (region) {
            region.isSelectable = true;
            region.checked = true;
          });
          this.getAreas();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  private getAreas() {
    var me = this;
    this.areaService.getAllForSelect().subscribe({
      next: response => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === "OK") {
          this.areas = responseData.data;
          this.areas.forEach(function (area) {
            area.isSelectable = true;
            area.checked = true;
          });
          this.areas = this.areas.filter(x => this.regions.filter(y => y.checked == true).map(x => x.id).includes(x.regionId));
          this.reloadDashboard();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  getAnalyticsOverview() {
    if(this.areaIds && this.areaIds.length > 0) {
      this.dashboardService.getAnalyticsOveriviewBuilding(this.areaIds).subscribe({
        next: (data) => {
          this.buildingsData = new AssetData(data?.body?.data, 'Buildings');
          this.buildingsData.items = this.buildingsData.items.map(item => ({
            ...item,
            key: `COMMON.BUILDING.TYPE.${item.key.toUpperCase()}`
          }));
          this.buildingsData.totalCount = this.buildingsData.items.find(x => x.key == 'COMMON.BUILDING.TYPE.TAGGED')?.value! + this.buildingsData.items.find(x => x.key == 'COMMON.BUILDING.TYPE.INACCESSIBLE')?.value!;
          this.buildingsData.totalCount = Number.isNaN(this.buildingsData.totalCount) ? 0 : this.buildingsData.totalCount;
          this.buildingsSalesManagementData = JSON.parse(JSON.stringify(this.buildingsData))
          this.buildingsDoughnutData = JSON.parse(JSON.stringify(this.buildingsData))
          this.buildingsSalesManagementData.items = this.buildingsSalesManagementData.items.filter(x => x.key != 'COMMON.BUILDING.TYPE.NEW' && x.key != 'COMMON.BUILDING.TYPE.EXISTING');
      
          this.buildingsDoughnutData.items = this.buildingsDoughnutData.items.filter(x => x.key === 'COMMON.BUILDING.TYPE.TAGGED' || x.key === 'COMMON.BUILDING.TYPE.INACCESSIBLE');     
      
          this.calculateIncreasement();
        },
        error: (error) => console.log(error.statusText)
      });
      this.dashboardService.getAnalyticsOveriviewCustomer(this.areaIds).subscribe({
        next: (data) => {
          this.customersData = new AssetData(data?.body?.data, 'Customers');
          console.log(this.customersData)
          this.customersData.items = this.customersData.items.map(item => ({
            ...item,
            key: `COMMON.CUSTOMER.TYPE.${item.key.toUpperCase()}`
          }));
        },
        error: (error) => console.log(error.statusText)
      });
      this.dashboardService.getAnalyticsOveriviewPole(this.areaIds).subscribe({
        next: (data) => {
          this.polesData = new AssetData(data?.body?.data, 'Poles');
          this.polesData.items = this.polesData.items.map(item => ({
            ...item,
            key: `COMMON.POLE.TYPE.${item.key.toUpperCase()}`
          }));
        },
        error: (error) => console.log(error.statusText)
      });
      this.dashboardService.getAnalyticsOveriviewDt(this.areaIds).subscribe({
        next: (data) => {
          this.transformersData = new AssetData(data?.body?.data, 'Transformers');
          this.transformersData.items = this.transformersData.items.map(item => ({
            ...item,
            key: `COMMON.TRANSFORMER.TYPE.${item.key.toUpperCase()}`
          }));
        },
        error: (error) => console.log(error.statusText)
      });
    }
  }


  getGraphData() {
    var request = {
      page: this.page,
      graphDataType: this.graphType,
      graphDateType : this.graphDateType,
      areaIds: this.areaIds,
    }
    this.dashboardService.getGraphData(request).subscribe({
      next: (data) => {
        this.graphData = this.formatGraphData(data.body);
      },
      error: (error) => console.log(error.statusText)
    });
  }

  getOperationsAnalytics(eventData: [number, string]) {
    const [chartPage, period] = eventData
    let obj = {
      page: chartPage,
      pageSize: this.pageSize,
      graphType: period
    };
    this.dashboardService.getOperationsAnalytics(obj).subscribe({
      next: (data) => {
        this.lineGraphData = this.formatLineGraphData(data.body);
      },
      error: (error) => console.log(error.statusText)
    });
  }

  calculateIncreasement() {

    let totalCount = this.buildingsData.totalCount + this.customersData.totalCount + this.transformersData.totalCount + this.polesData.totalCount;
    let monthCount = this.buildingsData.lastMonthCount + this.customersData.lastMonthCount + this.transformersData.lastMonthCount + this.polesData.lastMonthCount;

    let totalDiff = totalCount - monthCount;

    this.increasement = (monthCount / totalDiff) * 100;

  }

  formatGraphData(data: any) {

    let formatedGraphData = {
      labels: data?.data?.labels ? data?.data?.labels : [],
      datasets: [
        { data: data?.data?.customersCount ? data.data.customersCount : [], label: 'COMMON.CUSTOMER.PLURAL', borderRadius: 10, borderWidth: 0, backgroundColor: "#ff6347", hoverBackgroundColor: "#ff6347", barPercentage: 0.6 },
        { data: data?.data?.buildingsCount ? data.data.buildingsCount : [], label: 'COMMON.BUILDING.PLURAL', borderRadius: 10, borderWidth: 0, backgroundColor: "#FF991F", hoverBackgroundColor: "#FF991F", barPercentage: 0.6 },
        { data: data?.data?.dtsCount ? data.data.dtsCount : [], label: 'COMMON.TRANSFORMER.PLURAL', borderRadius: 10, borderWidth: 0, backgroundColor: "#0052CC", hoverBackgroundColor: "#0052CC", barPercentage: 0.6 },
        { data: data?.data?.polesCount ? data.data.polesCount : [], label: 'COMMON.POLE.PLURAL', borderRadius: 10, borderWidth: 0, backgroundColor: "#00875A", hoverBackgroundColor: "#00875A", barPercentage: 0.6 },
      ]
    };
    return formatedGraphData;
  }

  formatLineGraphData(data: any) {
    let formatedGraphData = {
      labels: data?.labels ? data.labels : [],
      datasets: [
        // { data: data.customersCount, label: 'Customers', fill: true, tension: 0.4, backgroundColor: '#ff6347', pointBackgroundColor: "#ff6347", borderColor: "#ff6347", pointRadius: 4 },
        { data: data?.surveysCount ? data.surveysCount : [], label: 'Surveys', fill: true, tension: 0.4,backgroundColor:"#E072A4", pointBackgroundColor: "#E072A4", borderColor: "#E072A4", pointRadius: 4 },
        { data: data?.installationsCount ? data.installationsCount : [], label: 'Installations', fill: true, tension: 0.4,backgroundColor:"#8F00FF", pointBackgroundColor: "#8F00FF", borderColor: "#8F00FF", pointRadius: 4 }
      ]
    };

    return formatedGraphData;
  }

  shareCheckedListAssets(selAssets: any[]) {
    this.selectedAssets.forEach(function (selectedAsset) {
      selectedAsset.checked = false;
    });
    this.selectedAssets.forEach(function (selectedAsset) {
      selAssets.forEach(function (selAsset) {
        if (selAsset === selectedAsset.id) {
          selectedAsset.checked = true;
        }
      });
    });
    this.reloadMapTrigger = !this.reloadMapTrigger;
  }

  shareCheckedListRegions(selectedRegionIds: any[]) {
    // this.getAreas();
    var x =false;
    if(this.regions.length===selectedRegionIds.length){
      this.selectedAllRegions=true;
    }else{
      this.selectedAllRegions=false;
    }  
    this.areas.forEach(function (area) {
      if (!selectedRegionIds.includes(area.regionId)) {
        area.checked = false;
          x = true;
      } else {
        if (area.isSelectable) {
          area.checked = true;
        }
      }
    });
     this.selectedAllAreas=!x;
    this.reloadDashboard();
  }
//Ne dirati ifove za selectedallareas i selected all regions
// ni sam bog ne zna kako radi ali radi.
  shareCheckedListAreas(selectedAreas: any[]) {
    let regs: any[] = [];
    if(this.areas.length===selectedAreas.length){
      this.selectedAllAreas=true;
    }else{
      this.selectedAllAreas=false;
    }    
    if(this.regions.length===regs.length){
      this.selectedAllRegions=true;
    }else{
      this.selectedAllRegions=false;
    }  
    this.areas.forEach(function (area) {
      if (selectedAreas.includes(area.id)) {
        area.checked = true;
        regs.push(area.regionId);
      } else {
        area.checked = false;
      }
    });
    var x = false;
    this.regions.forEach(function (region) {
      if (regs.includes(region.id)) {
        region.checked = true;
      }else{
        region.checked=false;
        x=true;
      }
    });
    this.selectedAllRegions=!x;
    this.reloadDashboard();
  }


}
