import { Area } from './area.model';
import { Building, BuildingImage } from './building.model';
import { DQStatus } from './dataQuality.model';
import { DtDto, getInitDtDto } from './dt.model';
import { HistoryOutput } from './history.model';
import { KeyValuePair } from './keyValueItem.model';
import { BillingType, getInitMeter, Meter } from './meter.model';

export class Customer {
  id: number;
  dataQualityId: number;
  slrn: string;
  name: string;
  accountNumber: string | null;
  phoneNumber?: string;
  email?: string;
  address: string;
  tariffName: string;
  isNew: boolean;
  meterNo?: string;
  dtName?: string;
  assetStatus?: AssetStatus;
  tenantName?: string;
  buildingOwner?: string;
  physicalAddress?: string;
  buildingId?: number;
  surveyStatus: string;
  oldMeterNumber?: string;
  area: Area;
  isOnBoard?: boolean;
  spn: string;
  dqStatus?: DQStatus;
  isChecked: boolean;

  constructor(data?: any) {
    this.id = data?.id ?? 0;
    this.dataQualityId = data?.dataQualityId ?? 0;
    this.slrn = data?.slrn ?? '';
    this.name = data?.name ?? '';
    this.accountNumber = data?.accountNumber ?? '';
    this.phoneNumber = data?.phoneNumber ?? '';
    this.email = data?.email ?? '';
    this.address = data?.address ?? '';
    this.tariffName = data?.tariffName ?? '';
    this.isNew = data?.isNew ?? false;
    this.meterNo = data?.meterNo ?? '';
    this.dtName = data?.dtName ?? '';
    this.assetStatus = data?.assetStatus;
    this.buildingOwner = data?.buildingOwner ?? '';
    this.physicalAddress = data?.physicalAddress ?? '';
    this.buildingId = data?.buildingId;
    this.surveyStatus = data?.surveyStatus ?? '';
    this.oldMeterNumber = data?.oldMeterNumber ?? '';
    this.area = new Area(data?.area);
    this.isOnBoard = data?.isOnBoard ?? false;
    this.spn = data?.spn ?? '';
    this.dqStatus = data?.dqStatus;
    this.isChecked = data?.isChecked ?? false;
  }
}

export interface CustomerCrudTable {
  id: number;
  region: string;
  area: string;
  feeder: string;
  dtName: string;
  dtNumber: string;
  buildingOwnerName: string;
  address: string;
  accountNumber: string;
  itinerary: string;
  meterNumber: string;
  tariffClass: string;
  customerType: string;
  contractNumber: string;
  blockNumber: number;
  geoCode: number;
  typeOfConnection: string;
  clientType: string;
  serviceType: string;
  activityCode: string;
  currentPlotCode: string;
  ghanaPostGPS: string;
  sltStatus: string;
  assetStatus?: string;
  postCode?: string;
  postCodeException?: string;
}

export interface CustomerBillingAccount {
  id: number;
  customerId: number;
  region: string;
  area: string;
  accountNumber: string;
  meterNumber: string;
  contractNumber: string;
  tariff: string;
  sltStatusId: number;
  slt: string;
  accountStatus: AccountStatus;
  address: string;
  meterCode: string;
  meterDigit: number;
  geoCode: string;
  itinerary: string;
  round: string;
  block: string;
  plot: string;
  readingFrequency: number;
  latitude?: number;
  longitude?: number;
}
export interface CustomerBillingAccountAdd extends CustomerBillingAccount {
  areaId: number;
  tariffId: number;
  regionId: number;
  utilityId: number;
}

export interface CustomerAdd extends Customer {
  blockNumber: number | null;
  geoCode: number | null;
  activityCode: string;
  currentPlotCode: string;
  ghanaPostGPS: string;
  sltStatus: SltStatus;
  customerType: CustomerType | null;
  buildingType: BuildingType;
  regionId: number;
  clientType: ClientType;
  history: HistoryOutput;
  serviceType: ServiceType;
  areaId: number;
  tariffId: number;
  dtId: number;
  typeOfConnection: TypeOfConnection;
  meter: Meter;
  billingType: BillingType;
  dt: DtDto;
  isNewConnection: boolean;
  utilityId: number;
}

export class History {
  label: string;
  values: string[];

  constructor(data?: any) {
    this.label = data?.label ?? '';
    this.values = data?.values ?? [];
  }
}
export class CustomerEdit extends Customer {
  building: Building;
  history: HistoryOutput;
  dt: DtDto;
  buildingType: BuildingType;
  meter: Meter;
  billImageUrl: string;
  cardImageUrl: string;
  meterImageUrl: string;
  meterNumberImageUrl: string;
  utilitySupplyImageUrl: string;
  supplyFuseImageUrl: string;
  backCardViewImageUrl: string;
  buildingImages: BuildingImage[];
  numberOfHouseholds?: number;
  contractNumber: string;
  typeOfConnection: TypeOfConnection;
  ghanaPostGps?: string;
  postCodeException?: string;
  buildingOwnerName: string;
  customerType: CustomerType;
  serviceType: ServiceType;
  sltStatus: SltStatus;
  clientType: ClientType;
  meterCondition: MeterCondition;
  meterStatus: MeterStatus;
  meterSlrn: string;
  cardNumber: string;
  closestLandmark: string;
  feederId: number;
  dtId: number;
  meterTypeId: number;
  meterNumber: string;
  remarksAndRecommendations?: string;
  lastUpdateTime?: string;
  validatedBy?: string;
  validatedDate?: string;
  auditedBy?: string;
  auditedDate?: string;
  auditedStatus?: string;
  dataQualityStatus?: DQStatus;
  guid: string;
  customerHistories: History[];
  lastPaymentAmount: string;
  lastPaymentDate: string;
  lastVendDate?: string;
  lastVendAmount?: number;
  currentDebtBalance: string;
  permiseType?: string;
  permiseCategory?: string;
  activity?: string;
  subActivity?: string;
  neighborhood: Neighborhood;
  numberOfRooms: number;
  tariffClass: string;
  actualTariffClass: string;
  customerInspectionConclusion: string;
  comment: string;
  dataValidationReview: KeyValuePair[] = [];
  taggedDate?: string;
  firstCaptureUser? : string;
  lastUpdateUser? : string;

  constructor(data?: any) {
    super();
    this.id = data?.id ?? 0;
    this.dataQualityId = data?.dataQualityId ?? 0;
    this.slrn = data?.slrn ?? '';
    this.name = data?.name ?? '';
    this.accountNumber = data?.accountNumber ?? '';
    this.phoneNumber = data?.phoneNumber ?? '';
    this.email = data?.email ?? '';
    this.address = data?.address ?? '';
    this.tariffName = data?.tariffName ?? '';
    this.isNew = data?.isNew ?? false;
    this.meterNo = data?.meterNo ?? '';
    this.dtName = data?.dtName ?? '';
    this.assetStatus = data?.assetStatus;
    this.buildingOwner = data?.buildingOwner ?? '';
    this.physicalAddress = data?.physicalAddress ?? '';
    this.buildingId = data?.buildingId ?? 0;
    this.surveyStatus = data?.surveyStatus ?? '';
    this.oldMeterNumber = data?.oldMeterNumber ?? '';
    this.area = new Area(data?.area);
    this.isOnBoard = data?.isOnBoard ?? false;
    this.spn = data?.spn ?? '';
    this.dqStatus = data?.dqStatus;
    this.isChecked = data?.isChecked ?? false;
    this.building = new Building(data?.building);
    this.history = new HistoryOutput(data?.history);
    this.dt = new DtDto(data?.dt);
    this.buildingType = data?.buildingType ?? BuildingType.NotSelected;
    this.meter = new Meter(data?.meter);
    this.billImageUrl = data?.billImageUrl ?? '';
    this.cardImageUrl = data?.cardImageUrl ?? '';
    this.meterImageUrl = data?.meterImageUrl ?? '';
    this.meterNumberImageUrl = data?.meterNumberImageUrl ?? '';
    this.utilitySupplyImageUrl = data?.utilitySupplyImageUrl ?? '';
    this.supplyFuseImageUrl = data?.supplyFuseImageUrl ?? '';
    this.backCardViewImageUrl = data?.backCardViewImageUrl ?? '';
    this.buildingImages = data?.buildingImages?.length
      ? data?.buildingImages.map((el: any) => new BuildingImage(el))
      : [];
    this.numberOfHouseholds = data?.numberOfHouseholds;
    this.contractNumber = data?.contractNumber ?? '';
    this.typeOfConnection =
      data?.typeOfConnection ?? TypeOfConnection.NotSelected;
    this.ghanaPostGps = data?.ghanaPostGps ?? '';
    this.postCodeException = data?.postCodeException ?? '';
    this.buildingOwnerName = data?.buildingOwnerName ?? '';
    this.customerType = data?.customerType ?? CustomerType.Prepaid;
    this.serviceType = data?.serviceType ?? ServiceType.AMR;
    this.sltStatus = data?.sltStatus ?? new SltStatus();
    this.clientType = data?.clientType ?? ClientType.NotSelected;
    this.meterCondition = data?.meterCondition ?? undefined;
    this.meterStatus = data?.meterStatus ?? undefined;
    this.meterSlrn = data?.meterSlrn ?? '';
    this.cardNumber = data?.cardNumber ?? '';
    this.closestLandmark = data?.closestLandmark ?? '';
    this.feederId = data?.feederId ?? 0;
    this.dtId = data?.dtId ?? 0;
    this.meterTypeId = data?.meterTypeId ?? 0;
    this.meterNumber = data?.meterNumber ?? '';
    this.remarksAndRecommendations = data?.remarksAndRecommendations ?? '';
    this.lastUpdateTime = data?.lastUpdateTime ?? '';
    this.validatedBy = data?.validatedBy ?? '';
    this.validatedDate = data?.validatedDate ?? '';
    this.auditedBy = data?.auditedBy ?? '';
    this.auditedDate = data?.auditedDate ?? '';
    this.auditedStatus = data?.auditedStatus ?? '';
    this.dataQualityStatus = data?.dataQualityStatus;
    this.customerHistories = data?.customerHistories?.length
      ? data?.customerHistories.map((el: any) => new History(el))
      : [];
    this.lastPaymentAmount = data?.lastPaymentAmount ?? '';
    this.lastPaymentDate = data?.lastPaymentDate ?? '';
    this.lastVendDate = data?.lastVendDate ?? '';
    this.lastVendAmount = data?.lastVendAmount;
    this.currentDebtBalance = data?.currentDebtBalance ?? '';
    this.permiseType = data?.permiseType ?? '';
    this.permiseCategory = data?.permiseCategory ?? '';
    this.activity = data?.activity ?? '';
    this.subActivity = data?.subActivity ?? '';
    this.neighborhood = data?.neighborhood ?? Neighborhood.Urban;
    this.numberOfRooms = data?.numberOfRooms ?? 0;
    this.tariffClass = data?.tariffClass ?? '';
    this.actualTariffClass = data?.actualTariffClass ?? '';
    this.customerInspectionConclusion =
      data?.customerInspectionConclusion ?? '';
    this.tenantName = data?.tenantName ?? '';
    this.comment = data?.comment ?? '';
    this.guid = data?.guid ?? '';
    this.dataValidationReview = data?.dataValidationReview ?? [];
    this.taggedDate = data?.taggedDate ?? '';
    this.firstCaptureUser = data?.firstCaptureUser ?? '';
    this.lastUpdateUser = data?.lastUpdateUser ?? '';
  }
}

export enum SortByOptions {
  NEWEST = 1,
  SLRN_ASC = 2,
  SLRN_DESC = 3,
  ACCNO_ASC = 4,
  ACCNO_DESC = 5,
  ID = 6,
  ID_ASC = 7,
  ID_DESC = 8,
  OLDEST = 9,
  LAST_UPDATE = 10,
}

export enum FilterByOptions {
  TOTAL = 1,
  EXISTING = 2,
  NEW = 3,
  ONBOARD = 4,
  ONBOARDED = 5,
  REGULARIZED = 6,
  RETAGGED = 7,
}
export enum NewEntryOptions 
{
    WithAccNo =1 ,
    MeterStartsWith = 2,
    AlphaMeterType= 3,
    InvalidMeterNo= 4,
    WithSPN = 5
}
export enum CustomerType {
  Prepaid = 1,
  Postpaid = 2,
}

export class SltStatus {
  id: number = 0;
  value: string = '';
  utilityId: number = 0;
}

export enum ClientType {
  NotSelected = -1,
  Company = 0,
  EmbassyForeignInstitution = 1,
  Individual = 2,
  Government = 3,
  Assembly = 4,
}

export enum ServiceType {
  NotSelected = -1,
  Large = 0,
  Small = 1,
  Prepaid = 2,
  AMR = 3,
}

export enum UserType {
  None = 0,
  FirstCapturedUser = 1,
  LastCapturedUser = 2,
  ReassignedBy = 3,
  ValidatedBy = 4,
  AuditedBy = 5,
}

export enum MeterCondition {
  Working = 1,
  NotWorking = 2,
  Burnt = 3,
}

export enum MeterStatus {
  Unmetered = 1,
  Metered = 2,
}

export enum OrganizationStatus {
  All = -1,
  Active = 0,
  Inactive = 1,
}

export enum AssetStatus {
  Pending = 0,
  Complete = 1,
  Incomplete = 2,
  Assigned = 'Assigned',
  Rejected = 4,
  Retagged = 5,
}

export enum BuildingType {
  NotSelected = 0,
  Residential = 1,
  Commercial = 2,
  Industrial = 3,
  Mixed = 4,
  ReligiousHouse = 5,
}

export enum TypeOfConnection {
  NotSelected = -1,
  SinglePhase = 0,
  ThreePhase = 1,
}

export enum Neighborhood {
  Urban = 0,
  PeriUrban = 1,
  Rural = 2,
}

export enum AccountStatus {
  NotSelected = 0,
  Active = 1,
  Inactive = 2
}

export class CustomerStats {
  total?: number;
  activeExisting?: number;
  inactiveExisting?: number;
  activeUnvalidated?: number;
  inactiveUnvalidated?: number;
  existing?: number;
  unaccessible?: number;
  onboard?: number;
  unvalidated?: number;
  regularized?: number;
  regularizedConsidered?: number;
  regularizedTotalRevenue?: number;
  retagged?: number;
  new?: number;
  withAccNo?: number;
  meterNumberStartsWith?: number;
  alphaMeterType?: number;
  invalidMeterNumber?: number;
  withSpn?:number;
  constructor(data?: any) {
    this.total = data?.total || undefined;
    this.existing = data?.existing || undefined;
    this.unaccessible = data?.unaccessible || undefined;
    this.onboard = data?.onboard || undefined;
    this.unvalidated = data?.unvalidated || undefined;
    this.regularized = data?.regularized || undefined;
    this.regularizedConsidered = data?.regularizedConsidered || undefined;
    this.regularizedTotalRevenue = data?.regularizedTotalRevenue || undefined;
    this.retagged = data?.retagged || undefined;
    this.activeExisting = data?.activeExisting || undefined;
    this.inactiveExisting = data?.inactiveExisting || undefined;
    this.activeUnvalidated = data?.activeUnvalidated || undefined;
    this.inactiveUnvalidated = data?.inactiveUnvalidated || undefined;
    this.withAccNo = data?.withAccNo || undefined;
    this.meterNumberStartsWith = data?.meterNumberStartsWith || undefined;
    this.alphaMeterType = data?.alphaMeterType || undefined;
    this.invalidMeterNumber = data?.invalidMeterNumber || undefined;
    this.new = data?.new || undefined;
    this.withSpn = data?.withSpn || undefined;

  }
}

export interface CustomerImage {
  url: string;
  imageType: any;
}

export class RegularizationStats {
  regularized?: number;
  queued?: number;
  failed?: number;
  constructor(data?: any) {
    this.regularized = data?.regularizedCount || undefined;
    this.queued = data?.queuedCount || undefined;
    this.failed = data?.failedCount || undefined;
  }
}

export enum PhoneNumberVerificationIssue
{
    Inactive = 1,
    Mismatch = 2,
    DeniedOwnership = 3,
    Invalid = 4
}
