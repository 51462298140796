<main class="container-fluid page-content">
  <div class="page-filter">
    <p class="logged-user">{{'DASHBOARD.GREETINGS.TITLE' | translate}}, {{ loggedUser }}</p>
    <p>{{'DASHBOARD.GREETINGS.DESCRIPTION' | translate}}</p>
  </div>

  <div class="row">
    <div class="d-flex dashboard-filter-container">
      <div class="col-1 d-flex show-text-container ml-2 mr-2">
        <p class="show-text">{{'DASHBOARD.FILTERS.LABEL' | translate}}:</p>
      </div>
      <app-dashboard-multi-select-dropdown
        [items]="selectedAssets"
        [label]="'COMMON.ASSET'"
        (shareCheckedList)="shareCheckedListAssets($event)"
        click-stop-propagation
      ></app-dashboard-multi-select-dropdown>
      <app-dashboard-multi-select-dropdown
        [items]="regions"
        [label]="'COMMON.BU'"
        [selectedAll]="selectedAllRegions"
        (shareCheckedList)="shareCheckedListRegions($event)"
        click-stop-propagation
      ></app-dashboard-multi-select-dropdown>
      <app-dashboard-multi-select-dropdown
        [items]="areas"
        [label]="'COMMON.UT'"
        [selectedAll]="selectedAllAreas"
        (shareCheckedList)="shareCheckedListAreas($event)"
        click-stop-propagation
      ></app-dashboard-multi-select-dropdown>
    </div>
  </div>
  <analytics-overview
    [polesData]="polesData"
    [buildingsData]="buildingsData"
    [customersData]="customersData"
    [transformersData]="transformersData"
  ></analytics-overview>

  <sales-management
    *ngIf="areaIds.length > 0"
    [polesData]="polesData"
    [buildingsData]="buildingsSalesManagementData"
    [customersData]="customersData"
    [transformersData]="transformersData"
    [increasement]="increasement"
    [mapData]="selectedAssets"
    [areaIds]="areaIds"
    [reloadMapTrigger]="reloadMapTrigger"
  ></sales-management>
  
  <operations-analytics
    [counts]="assetsCounts"
    [lineGraphData]="lineGraphData"
    [customersData]="customersData"
    (getData)="getOperationsAnalytics($event)"
  ></operations-analytics>

  <div class="row">
    <div class="card-wrapper col-sm-12 col-md-6 col-xxl-6">
      <h2 class="card-title mb-25">{{ 'DASHBOARD.ASSET_BREAKDOWN.TITLE' | translate }}</h2>
      <div class="card doughnuts">
        <div class="row">
          <div class="col-md-4 p-5">
            <app-doughnut-chart
              [data]="buildingsDoughnutData.items"
              [title]="'COMMON.BUILDING.PLURAL'"
              [colors]="buildingsColors"
            ></app-doughnut-chart>
          </div>
          <div class="col-md-4 p-5">
            <app-doughnut-chart
              [data]="transformersData.items"
              [title]="'COMMON.TRANSFORMER.PLURAL'"
              [colors]="dtColors"
            ></app-doughnut-chart>
          </div>
          <div class="col-md-4 p-5">
            <app-doughnut-chart
              [data]="polesData.items"
              [title]="'COMMON.POLE.LT_PLURAL'"
              [colors]="poleColors"
            ></app-doughnut-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="card-wrapper col-sm-12 col-md-6 col-xxl-6 ps-0">
      <div class="d-flex">
        <div>
          <h2 class="card-title mb-25">{{ 'DASHBOARD.ASSET_BREAKDOWN.TITLE' | translate }}</h2>
        </div>
        <div class="ms-auto">
          <div class="filter-bar anti-zoom" style="display: unset;">
            <div class="periods mb-3">
              
              <span
                [ngClass]="
                graphDateType === 'TaggedDate' ? 'period-item active' : 'period-item'
                "
                (click)="changeGraphDateType('TaggedDate')"
                >{{'DASHBOARD.ASSET_BREAKDOWN.DATE' | translate}}</span
              >
              <span
                [ngClass]="
                graphDateType === 'LastUpdateTime' ? 'period-item active' : 'period-item'
                "
                (click)="changeGraphDateType('LastUpdateTime')"
                >{{'DASHBOARD.ASSET_BREAKDOWN.UPDATE' | translate}}</span
              >
            </div>
            <div class="periods mb-3">
              <span
                [ngClass]="
                  graphType === 'Yearly' ? 'period-item active' : 'period-item'
                "
                (click)="changeGraphType('Yearly')"
                >{{'COMMON.YEARLY' | translate}}</span
              >
              <span
                [ngClass]="
                  graphType === 'Monthly' ? 'period-item active' : 'period-item'
                "
                (click)="changeGraphType('Monthly')"
                >{{'COMMON.MONTHLY' | translate}}</span
              >
              <span
                [ngClass]="
                  graphType === 'Daily' ? 'period-item active' : 'period-item'
                "
                (click)="changeGraphType('Daily')"
                >{{'COMMON.DAILY' | translate}}</span
              >
            </div>

            
          </div>
        </div>
        <div class="ms-auto">
          <div class="date">
            <span class="arrow" (click)="increasePage(1)">
              <svg
                class="primary-color"
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.2431 6.34317L14.8288 4.92896L7.75781 12L14.8289 19.0711L16.2431 17.6569L10.5862 12L16.2431 6.34317Z"
                />
              </svg>
            </span>
            <span class="value"
              >{{ graphData.labels[0] }} -
              {{ graphData.labels[graphData.labels.length - 1] }}</span
            >
            <span class="arrow" (click)="increasePage(-1)">
              <svg
                class="primary-color"
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 6.41422L9.4143 5L16.4853 12.0711L9.4143 19.1422L8 17.7279L13.6569 12.0711L8 6.41422Z"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <bar-chart
        class="card bar"
        id="chart"
        [graphData]="graphData"
      ></bar-chart>
    </div>
  </div>
</main>
