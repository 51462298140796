<div id="map-component" class="map-component" [class.expanded]="isMapExpanded">
  <div class="div-map-search anti-zoom">
    <input class="input-map-search" (keyup.enter)="getMapData(isSearch)" type="text" [(ngModel)]="searchValue" [placeholder]="'DASHBOARD.SALES_MANAGEMENT.MAP_PLACEHOLDER' | translate" />
   <div class="search-wrapper">
      <svg (click)="getMapData(isSearch)" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3952 12.9761C16.852 9.8209 16.63 5.25612 13.7295 2.3556C10.5887 -0.785201 5.49642 -0.785201 2.35561 2.3556C-0.785204 5.49642 -0.785204 10.5887 2.35561 13.7295C5.25614 16.63 9.82096 16.8519 12.9762 14.3952C12.9897 14.4106 13.0039 14.4257 13.0186 14.4403L17.2838 18.7055C17.6764 19.0982 18.313 19.0982 18.7056 18.7055C19.0981 18.3129 19.0981 17.6764 18.7056 17.2838L14.4404 13.0186C14.4256 13.0039 14.4105 12.9898 14.3952 12.9761ZM12.2426 3.75737C14.5858 6.10051 14.5858 9.89947 12.2426 12.2427C9.8995 14.5858 6.1005 14.5858 3.75736 12.2427C1.41421 9.89947 1.41421 6.10051 3.75736 3.75737C6.1005 1.41421 9.8995 1.41421 12.2426 3.75737Z" fill="#8F92A1"/>
      </svg>
    </div>
  </div>

    <agm-map
        id="agm-map"
        (zoomChange)="onZoomChange($event)"
        (boundsChange)="mapBoundsChange($event)"
        (mapReady)="mapReady($event)"
        (tilesLoaded)="loaded()"
        (idle)="mapIdleListener()"
        [latitude]="lat"
        [longitude]="lng"
        [zoom]="zoom"
        [mapTypeControl]="true"
        [streetViewControl]="true"
        [fullscreenControl]="false"
        >
        <agm-overlay tooltip="data.additionalInfo" *ngFor="let data of mapData" [latitude]="data.latitude" [longitude]="data.longitude">
            <div *ngIf="data.count > 1; else pin" class="map-point anti-zoom" [ngClass]="data.type | lowercase" (click)="zoomCluster(data)">
                {{ data.count }}
            </div>
            <ng-template #pin>
                <div class="pin anti-zoom">
                    <div class="pin-item"
                        (mouseenter)="onAssetMouseEnter($event, data)"
                        (mouseleave)="onAssetMouseLeave()"
                        (click)="zoomPoint($event, data)"
                        [ngClass]="data.type | lowercase"></div>

                    <div id="tooltip-div" class="map-tooltip"
                         [ngClass]="{
                            'visible': data?.additionalInfo?.id && hoveredMapItemId === data?.additionalInfo?.id,
                            'move-left': moveTooltipLeft,
                            'move-bottom': moveTooltipBottom,
                            'move-right': moveTooltipRight,
                            'move-top': moveTooltipTop,
                            'move-top-right': moveTooltipTopRight,
                            'move-bottom-right': moveTooltipBottomRight
                        }">
                        <div class="close-tooltip" (click)="hideTooltip()">x</div>
                        <div id="tooltip-id">
                            <div  class="tooltip-asset">
                            <div class="dot-tooltip" [ngClass]="entity | lowercase"></div>
                            <div class="entity-name" *ngIf="entity != 'CUSTOMER' ">
                                <div>{{entity}} :</div>
                                <div>&nbsp;{{additionalInfo}}</div>
                            </div>
                            <div class="entity-name" *ngIf="entity == 'CUSTOMER' ">{{entity}}</div>
                            </div>
                            <hr>
                            <div class="tooltip-info tooltip-info-building" *ngIf="tooltipBuilding != null">
                            No. of accounts: <b>{{ tooltipBuilding.numberOfAccounts }}</b>
                            </div>
                            <div class="tooltip-info tooltip-info-building" *ngIf="tooltipPole != null">
                            ID: <b>{{ tooltipPole.id }}</b><br>
                            Type: <b>{{ tooltipPole.poleType }}</b><br>
                            No. of buildings: <b>{{ tooltipPole.numberOfBuildings }}</b><br>
                            </div>
                            <div class="tooltip-info tooltip-info-building" *ngIf="tooltipDt != null">
                            Name: <b>{{ tooltipDt.dtName }}</b><br>
                            Type: <b>{{ tooltipDt.dtType }}</b><br>
                            Status: <b>{{ tooltipDt.dtStatus }}</b><br>
                            Customer population: <b>{{ tooltipDt.customerPopulation }}</b><br>
                            No. of Buildings: <b>{{ tooltipDt.numberOfBuildings }}</b><br>
                            No. of Poles: <b>{{ tooltipDt.numberOfPoles }}</b><br>
                            </div>
                            <div *ngIf="buildingCustomers != null && buildingCustomers.length > 0" class="tooltip-info">
                            <table class="table table-striped table-hover">
                                <tr>
                                <th>#</th>
                                <th>Acc No.</th>
                                <th>Meter No.</th>
                                </tr>
                                <tr *ngFor="let customer of buildingCustomers; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ customer.accountNumber }}</td>
                                <td>{{ customer.meterNumber }}</td>
                                </tr>
                            </table>
                            </div>
                            <div class="tooltip-info tooltip-info-customer" *ngIf="tooltipCustomer != null">
                            Acc. No: <b>{{ tooltipCustomer.accountNumber }}</b><br/>
                            MSN: <b>{{ tooltipCustomer.meterNumber }}</b><br/>
                            SLRN: <b>{{ tooltipCustomer.slrn }}</b><br/>
                            SLT: <b>{{ tooltipCustomer.slt }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </agm-overlay>
    </agm-map>

    <div class="map-expand-option" (click)="toggleMapExpand()">
        <img *ngIf="!isMapExpanded" src="../../../assets/icons/expand_screen.svg" alt="Expand screen icon"/>
        <img *ngIf="isMapExpanded" src="../../../assets/icons/minimize_screen.svg" alt="Mimimize screen icon" />
    </div>

    <div id="streetview-small" [class.hide]="!isStreetViewVisible" #streetviewSmall>
        <div class="text">No street view available for selected location</div>
        <div (click)="isStreetViewVisible = false" class="x-container"><img src="../../../assets/icons/close.svg" alt="Close icon" /></div>
    </div>
</div>
